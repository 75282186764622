import "bootstrap/dist/css/bootstrap.css";
//core
import "primereact/resources/primereact.min.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import 'primeicons/primeicons.css';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBz4iSF_Ujw3dighO2ceq2QouKWvb-VWRU",
	authDomain: "rotapadv3-b3a1c.firebaseapp.com",
	projectId: "rotapadv3-b3a1c",
	// storageBucket: "rotapadv3-b3a1c.appspot.com",
	messagingSenderId: "329387562735",
	appId: "1:329387562735:web:c9da7636406df98c036d1a",
	measurementId: "G-NQPS1V9TS4",
	databaseURL: "https://rotapadv3-b3a1c-default-rtdb.firebaseio.com/",
	storageBucket: "gs://rotapadv3-b3a1c.appspot.com",
};
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const analytics = getAnalytics(app);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App app={app}
					analytics={analytics}
					auth={auth}
					database={database}
					storage={storage} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
