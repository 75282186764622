import styled from "@emotion/styled";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";
import ReadOnlyField from "../ReadOnlyField";

export const StyledLabel = styled.label`
	font-size: 12px;
	display: inline-block;
	font-weight: 500;
`;
export const StyledH5 = styled.h5`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
	font-size: 18px;
`;

export const StyledH4 = styled.h4`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
	font-size: 24px;
	text-align: center;
	padding-bottom: 18px;
`;

export const StyledInput = styled.input`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

export const StyledCalendarDiv = styled.div`
	.p-calendar {
		width: 100%;
	}
	input {
		padding: 8px 8px;
		font-size: 12px;
		width: 100% !important;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
			Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
			"Segoe UI Symbol";
		color: #495057;
		background: #ffffff;
		border: 1px solid #ced4da;
		transition: background-color 0.2s, color 0.2s, border-color 0.2s,
			box-shadow 0.2s;
		appearance: none;
		border-radius: 6px;
		margin: 0;

		&:hover {
			border-color: #6366f1;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			box-shadow: 0 0 0 0.2rem #c7d2fe;
			border-color: #6366f1;
		}
		&:disabled {
			background-color: #e9ecef;
			opacity: 0.6;
			&:hover {
				border-color: #ced4da;
			}
		}
	}
`;
export const StyledInputTextArea = styled.textarea`
	padding: 8px 8px;
	font-size: 12px;
	width: 100% !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	color: #495057;
	background: #ffffff;
	border: 1px solid #ced4da;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s,
		box-shadow 0.2s;
	appearance: none;
	border-radius: 6px;
	margin: 0;

	&:hover {
		border-color: #6366f1;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem #c7d2fe;
		border-color: #6366f1;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 0.6;
		&:hover {
			border-color: #ced4da;
		}
	}
`;

const StyledDropDownDiv = styled.div`
	// border-width: 0 0 0 2px;
	// border-style: solid;
	// padding-left: 12px;
	// border-color: #6554c0;
	color: #6554c0;
	font-size: 18px;
	.customDropdown {
		width: 100%;
	}
	.customDropdown:not(.p-disabled):hover {
		border-color: #6366f1;
	}

	.customDropdown .p-dropdown-label {
		background: transparent;
		border: 0 none;
		padding: 8px 8px;
		font-size: 12px;
	}

	.customDropdown .p-dropdown-trigger {
		width: 2rem;
	}

	.customDropdown .p-dropdown-trigger-icon {
		font-size: 10px;
	}

	.p-disabled,
	.p-component:disabled {
		opacity: 0.6;
		background-color: #e9ecef;
	}
`;

const StepOne = ({ activeIndex, onSelect, workOrder, handleFormSubmit }) => {
	const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
	const getFormErrorMessage = (meta) => {
		return (
			isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
		);
	};

	const [recaptchaValue, setRecaptchaValue] = useState(null);

	const validate = (data) => {
		let errors = {};

		if (!data.firstName) {
			errors.firstName = "First name is required.";
		}
		if (!data.lastName) {
			errors.lastName = "Last name is required.";
		}

		if (!data.email) {
			errors.email = "Email is required.";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
			errors.email = "Invalid email address. E.g. example@email.com";
		}

		if (!data.companyName) {
			errors.companyName = "Company name is required.";
		}
		if (!data.industry) {
			errors.industry = "Industry name is required.";
		}

		if (!data.employeeCount) {
			errors.employeeCount = "Employee count is required.";
		}

		if (!recaptchaValue) {
			errors.recaptcha = "Please complete the reCAPTCHA";
		}

		return errors;
	};
	// console.log("activeIndex ==== ", activeIndex);
	return (
		<div className='d-flex p-0'>
			<Form
				onSubmit={(vals) =>
					handleFormSubmit({ ...vals, recaptcha: recaptchaValue })
				}
				initialValues={workOrder}
				validate={validate}
				render={({
					handleSubmit,
					pristine,
					submitting,
					hasValidationErrors,
					invalid,
				}) => {
					return (
						<form onSubmit={handleSubmit} className='w-100'>
							{activeIndex === 0 && (
								<>
									<StyledH4 className='mt-2 mb-2'>
										Start your 30-day free trial <br />
										<small className='text-muted' style={{ fontSize: "12px" }}>
											No card required or sign-up fees
										</small>
									</StyledH4>
									<StyledH5 className='mt-2 mb-2'>
										Contact Information{" "}
									</StyledH5>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='firstName'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel
															htmlFor='firstName'
															className={classNames({
																"p-error": isFormFieldValid(meta),
															})}>
															First name <span className='text-danger'>*</span>
														</StyledLabel>
														<StyledInput
															id='firstName'
															// placeholder='Enter title'
															{...input}
															className={classNames({
																"p-invalid": isFormFieldValid(meta),
															})}
														/>
													</div>
													{getFormErrorMessage(meta)}
												</div>
											)}
										/>
									</div>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='lastName'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel
															htmlFor='lastName'
															className={classNames({
																"p-error": isFormFieldValid(meta),
															})}>
															Last name <span className='text-danger'>*</span>
														</StyledLabel>
														<StyledInput
															id='lastName'
															// placeholder='Enter title'
															{...input}
															className={classNames({
																"p-invalid": isFormFieldValid(meta),
															})}
														/>
													</div>
													{getFormErrorMessage(meta)}
												</div>
											)}
										/>
									</div>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='phone'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel htmlFor='phone'>
															Phone number
														</StyledLabel>
														<StyledInput
															id='phone'
															// placeholder='Enter title'
															{...input}
														/>
													</div>
												</div>
											)}
										/>
									</div>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='email'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel
															htmlFor='email'
															className={classNames({
																"p-error": isFormFieldValid(meta),
															})}>
															Email <span className='text-danger'>*</span>
														</StyledLabel>
														<StyledInput
															id='email'
															// placeholder='Enter email'
															{...input}
															className={classNames({
																"p-invalid": isFormFieldValid(meta),
															})}
														/>
													</div>
													{getFormErrorMessage(meta)}
												</div>
											)}
										/>
									</div>
								</>
							)}

							{activeIndex === 1 && (
								<>
									<StyledH4 className='mt-2 mb-2'>
										Start your 30-day free trial <br />
										<small className='text-muted' style={{ fontSize: "12px" }}>
											No card required or sign-up fees
										</small>
									</StyledH4>

									<StyledH5 className='mt-2 mb-2'>Company Information</StyledH5>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='companyName'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel
															htmlFor='companyName'
															className={classNames({
																"p-error": isFormFieldValid(meta),
															})}>
															Company name{" "}
															<span className='text-danger'>*</span>
														</StyledLabel>
														<StyledInput
															id='companyName'
															// placeholder='Enter title'
															{...input}
															className={classNames({
																"p-invalid": isFormFieldValid(meta),
															})}
														/>
													</div>
													{getFormErrorMessage(meta)}
												</div>
											)}
										/>
									</div>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='industry'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel
															htmlFor='industry'
															className={classNames({
																"p-error": isFormFieldValid(meta),
															})}>
															Select your industry{" "}
															<span className='text-danger'>*</span>
														</StyledLabel>
														<StyledDropDownDiv>
															<Dropdown
																id='industry'
																{...input}
																options={[
																	{ value: "agriculture", name: "Agriculture" },
																	{ value: "automotive", name: "Automotive" },
																	{ value: "banking", name: "Banking" },
																	{
																		value: "construction",
																		name: "Construction",
																	},
																	{ value: "consulting", name: "Consulting" },
																	{ value: "education", name: "Education" },
																	{ value: "energy", name: "Energy" },
																	{
																		value: "entertainment",
																		name: "Entertainment",
																	},
																	{ value: "fashion", name: "Fashion" },
																	{
																		value: "financial_services",
																		name: "Financial Services",
																	},
																	{
																		value: "food_and_beverage",
																		name: "Food and Beverage",
																	},
																	{ value: "healthcare", name: "Healthcare" },
																	{ value: "hospitality", name: "Hospitality" },
																	{
																		value: "information_technology",
																		name: "Information Technology",
																	},
																	{ value: "insurance", name: "Insurance" },
																	{
																		value: "manufacturing",
																		name: "Manufacturing",
																	},
																	{ value: "media", name: "Media" },
																	{ value: "mining", name: "Mining" },
																	{ value: "real_estate", name: "Real Estate" },
																	{
																		value: "telecommunications",
																		name: "Telecommunications",
																	},
																	{
																		value: "transportation",
																		name: "Transportation",
																	},
																	{ value: "retail", name: "Retail" },
																	{ value: "tourism", name: "Tourism" },
																	{
																		value: "pharmaceutical",
																		name: "Pharmaceutical",
																	},
																	{ value: "technology", name: "Technology" },
																	{ value: "telecoms", name: "Telecoms" },
																	// Add more industry options as needed
																]}
																filter
																optionLabel='name'
																className='customDropdown'
															/>
														</StyledDropDownDiv>
														{/* <StyledInput
														id='industry'
														// placeholder='Enter title'
														{...input}
														className={classNames({
															"p-invalid": isFormFieldValid(meta),
														})}
													/> */}
													</div>
													{getFormErrorMessage(meta)}
												</div>
											)}
										/>
									</div>

									<div className='col-12 mt-1 mb-1'>
										<Field
											name='employeeCount'
											render={({ input, meta }) => (
												<div className='field'>
													<div className='block'>
														<StyledLabel
															htmlFor='employeeCount'
															className={classNames({
																"p-error": isFormFieldValid(meta),
															})}>
															Number of employees{" "}
															<span className='text-danger'>*</span>
														</StyledLabel>
														<StyledDropDownDiv>
															<Dropdown
																id='employeeCount'
																{...input}
																options={[
																	{ value: "1-10", name: "1 - 10 employees" },
																	{ value: "11-20", name: "11 - 20 employees" },
																	{ value: "21-30", name: "21 - 30 employees" },
																	{ value: "31-50", name: "31 - 50 employees" },
																	{ value: "51+", name: "51+ employees" },
																]}
																optionLabel='name'
																className='customDropdown'
															/>
														</StyledDropDownDiv>
														{/* <StyledInput
														id='employeeCount'
														// placeholder='Enter title'
														{...input}
														className={classNames({
															"p-invalid": isFormFieldValid(meta),
														})}
													/> */}
													</div>
													{getFormErrorMessage(meta)}
												</div>
											)}
										/>
									</div>
								</>
							)}

							{activeIndex === 2 && (
								<>
									<StyledH5 className='mt-2 mb-2'>
										Review your information
									</StyledH5>

									<div className='container'>
										<div className='row'>
											<div className='col-xs-12 col-md-12 col-lg-6'>
												<StyledLabel>Name</StyledLabel>
												<p className='text-muted' style={{ fontSize: "12px" }}>
													<Field
														name='firstName'
														subscription={{ value: true }}>
														{({ input: { value } }) => {
															return <>{value}</>;
														}}
													</Field>{" "}
													<Field name='lastName' subscription={{ value: true }}>
														{({ input: { value } }) => {
															return <>{value}</>;
														}}
													</Field>
												</p>

												<ReadOnlyField label='Email' fieldName='email' />

												<ReadOnlyField label='Phone' fieldName='phone' />
											</div>
											<div className='col-xs-12 col-md-12 col-lg-6'>
												<ReadOnlyField
													label='Company Name'
													fieldName='companyName'
												/>

												<ReadOnlyField label='Industry' fieldName='industry' />

												<ReadOnlyField
													label='Number of employees'
													fieldName='employeeCount'
												/>
											</div>
										</div>
									</div>
								</>
							)}

							{/* {activeIndex === 4 && (
								<Field name='start' subscription={{}}>
									{({ input }) => {
										return <>{input.onChange(moment().format())}</>;
									}}
								</Field>
							)} */}

							<div className='row mt-2'>
								{activeIndex === 2 && (
									<div className='col-12'>
										<p
											className='text-end text-muted'
											style={{ fontSize: "14px" }}>
											By clicking "Get started" you agree to RotaPads's{" "}
											<a
												href='https://rotapad.com/'
												target='_blank'
												rel='noreferrer'>
												Terms of Service
											</a>{" "}
											and{" "}
											<a
												href='https://rotapad.com/'
												target='_blank'
												rel='noreferrer'>
												Privacy Policy
											</a>
										</p>

										<div className='float-end pb-2'>
											<Field name='recaptcha'>
												{() => (
													<ReCAPTCHA
														sitekey='6LdUVE4mAAAAABTHwIbMub8wl84HGBGNnbmfTTE9'
														onChange={(x) => setRecaptchaValue(x)}
													/>
												)}
											</Field>
											<Field
												name='recaptcha'
												subscription={{ touched: true, error: true }}>
												{({ meta }) => {
													return <>{getFormErrorMessage(meta)}</>;
												}}
											</Field>
										</div>
									</div>
								)}
								<div className='col-12 d-flex flex-row-reverse'>
									{activeIndex === 2 ? (
										<>
											{/* <Button
												type='submit'
												disabled={pristine || submitting || hasValidationErrors}
												className='mt-2 p-button-sm '>
												Submit
												<i
													className={`${
														submitting ? "pi pi-spin pi-spinner" : ""
													}`}
													style={{
														fontSize: "12px",
														marginLeft: "4px",
													}}></i>
											</Button> */}
											<button
												className='p-button p-component mt-2 p-button-sm me-0'
												type='submit'
												disabled={pristine || submitting || invalid}>
												Get Started
												<i
													className={`${
														submitting ? "pi pi-spin pi-spinner" : ""
													}`}
													style={{
														fontSize: "12px",
														marginLeft: "4px",
													}}></i>
											</button>
										</>
									) : (
										<Button
											type='button'
											className='mt-2 p-button-sm '
											onClick={() => {
												onSelect(activeIndex + 1);
												setRecaptchaValue(null);
											}}
											{...(activeIndex === 0 && {
												disabled: pristine,
											})}>
											Next
											<i
												className={`${
													submitting
														? "pi pi-spin pi-spinner"
														: activeIndex !== 2
														? "pi pi-arrow-right"
														: ""
												}`}
												style={{
													fontSize: "12px",
													marginLeft: "4px",
												}}></i>
										</Button>
									)}

									{activeIndex !== 0 && (
										<Button
											type='button'
											onClick={() => {
												onSelect(activeIndex - 1);
												setRecaptchaValue(null);
											}}
											className='mt-2 p-button-sm me-2'
											{...(activeIndex === 0 && {
												disabled: pristine || submitting || hasValidationErrors,
											})}
											// disabled={pristine || hasValidationErrors}
										>
											<i
												className='pi pi-arrow-left'
												style={{
													fontSize: "12px",
													marginRight: "4px",
												}}></i>
											Previous
										</Button>
									)}
								</div>
							</div>
						</form>
					);
				}}
			/>
		</div>
	);
};

export default StepOne;
