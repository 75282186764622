import { Steps } from "primereact/steps";
import React, { useMemo } from "react";

const Stepper = ({ activeIndex, onSelect }) => {
	const items = useMemo(() => {
		return [
			{
				label: (
					<span
						className='d-flex flex-column text-center  text-truncate'
						style={{ fontSize: "11px", width: "120px" }}>
						<span>Step 1</span>
						<span style={{ color: "#6c757dbd" }} className='text-truncate'>
							Your Information
						</span>
					</span>
				),
			},
			{
				label: (
					<span
						className='d-flex flex-column text-center  text-truncate'
						style={{ fontSize: "11px", width: "120px" }}>
						<span>Step 2</span>
						<span style={{ color: "#6c757dbd" }} className='text-truncate'>
							Describe your Company
						</span>
					</span>
				),
			},
			
		
		];
	}, []);

	const renderSteps = useMemo(() => {
		return (
			<Steps model={items} onSelect={onSelect} activeIndex={activeIndex} />
		);
	}, [activeIndex, items, onSelect]);

	return <div className=''>{renderSteps}</div>;
};

export default Stepper;
