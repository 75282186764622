import styled from "@emotion/styled";
import { child, get, ref, set } from "firebase/database";
import _ from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { generate as generateUUID } from "short-uuid";
import "./App.css";
import StepOne from "./components/Form/StepOne";
import Stepper from "./components/Stepper";
import Logo from "./logo.png";

const StyledDivCheck = styled.div`
	width: 80px;
	background: #22c55e;
	margin: auto;
	border-radius: 60px;
`;

const StyledDivSuccessText = styled.div`
	color: #22c55e;
	font-weight: bold;
`;
const initValues = {
	companyName: "",
	industry: "",
	employeeCount: "",
	firstName: "",
	lastName: "",
	phone: "",
	email: "",
};

function App({ app, analytics, auth, database, storage }) {
	const [activeIndex, setActiveIndex] = useState(0);
	const [workOrder, setWorkOrder] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState(undefined);
	const [requesterEmail, setRequesterEmail] = useState(null);

	const handlFormSubmit = useCallback(
		async (values) => {
			const {
				companyName,
				industry,
				employeeCount,
				firstName,
				lastName,
				phone,
				email,
			} = values || {};
			const uuid = generateUUID();
			const payload = _.pickBy(
				{
					start: moment().format(),
					title: email + " RotaPad Trial",
					description: "RotaPad Trial",
					end: "",
					location: "",
					reporter: _.pickBy(
						{
							name: firstName + " " + lastName,
							email,
							phone,
							companyName,
							industry,
							employeeCount,
							createdAt: moment().format(),
						},
						_.identity
					),
					tags: [{ name: "External", value: "External" }],
					uid: uuid,
					workspaceId: "7h5RXvG5U7P3W27p7xyDFx",
				},
				_.identity
			);

			// console.log("payload ====== ", payload)

			try {
				const dbRef = ref(database);
				const getUser = await get(
					child(
						dbRef,
						`workspaces/7h5RXvG5U7P3W27p7xyDFx/workorders/${email.replace(
							".",
							","
						)}`
					)
				);

				if (getUser.exists()) {
					setWorkOrder(initValues);
					setShowErrorMessage(true);
					setShowMessage(false);
					setActiveIndex(0);
					setErrorMessage(`Email address (${email}) already exists`);
				} else {
					await set(
						ref(
							database,
							`workspaces/7h5RXvG5U7P3W27p7xyDFx/workorders/${email.replace(
								".",
								","
							)}`
						),
						payload
					);

					setWorkOrder(initValues);
					setShowMessage(true);
					setShowErrorMessage(false);
					setActiveIndex(0);
					setRequesterEmail(email);
				}
			} catch (e) {
				setWorkOrder(initValues);
				setShowErrorMessage(true);
				setShowMessage(false);
				setActiveIndex(0);
			}

			// try {
			// 	await set(
			// 		ref(
			// 			database,
			// 			`workspaces/7h5RXvG5U7P3W27p7xyDFx/workorders/${email.replace(
			// 				".",
			// 				","
			// 			)}`
			// 		),
			// 		payload
			// 	);

			// 	setWorkOrder(initValues);
			// 	setShowMessage(true);
			// 	setShowErrorMessage(false);
			// 	setActiveIndex(0);
			// 	setRequesterEmail(email);
			// } catch (e) {
			// 	setWorkOrder(initValues);
			// 	setShowErrorMessage(true);
			// 	setShowMessage(false);
			// 	setActiveIndex(0);
			// }
		},
		[database]
	);

	return (
		<div className='col-lg-8 mx-auto p-4 py-md-5'>
			<header className='d-flex align-items-center pb-3 mb-4'>
				<a
					href='https://rotapad.com'
					className='d-flex align-items-center text-dark text-decoration-none m-auto'>
					<img src={Logo} alt='RotaPad Logo' width='140' height='32' />
				</a>
			</header>

			<main>
				{showErrorMessage && (
					<p style={{ color: "red" }} className='text-center'>
						{errorMessage ||
							`There was an error, kindly contact support@rotapad.com`}
					</p>
				)}
				{showMessage ? (
					<>
						<div className='d-flex flex-column text-center'>
							<StyledDivSuccessText>Success!</StyledDivSuccessText>
							<div className='my-3'>
								<StyledDivCheck className='p-4'>
									<i
										className='pi pi-check'
										style={{
											color: "#fff",
											fontSize: "25px",
											fontWeight: "bold",
										}}></i>
								</StyledDivCheck>
							</div>
							<div style={{ fontWeight: "bold" }}>
								An email with further instructions has been sent to{" "}
								{requesterEmail} <br />
								Thank you for choosing RotaPad!
							</div>
							<div className='mt-3 text-muted' style={{ fontSize: "12px" }}>
								If you have any questions, please call{" "}
								<strong>+1 403 390 1196</strong> or email{" "}
								<a href='mailto:priority@sxcreationsgroup.com'>
									try@rotapad.com
								</a>
							</div>

							<div className='my-3'>
								<button
									className='p-button p-component mt-2 p-button-sm me-2 fw-semibold'
									type='button'
									onClick={() => {
										setShowMessage(false);
										setShowErrorMessage(false);
										setActiveIndex(0);
										setRequesterEmail(null);
									}}>
									Submit another trial request
								</button>
							</div>
						</div>
					</>
				) : (
					<>
						<Stepper
							activeIndex={activeIndex}
							onSelect={(e) => setActiveIndex(e)}
						/>
						<div className='mt-4 ms-2 me-2 ps-2 pe-2'>
							<StepOne
								activeIndex={activeIndex}
								onSelect={(e) => setActiveIndex(e)}
								workOrder={workOrder}
								handleFormSubmit={handlFormSubmit}
							/>
						</div>
					</>
				)}
			</main>
		</div>
	);
}

export default App;
